import React from "react";
import "./testimonials.css";
import AVT1 from '../../assets/CR1.jpg'
import AVT2 from '../../assets/CR2.jpg'
import AVT3 from '../../assets/CR3.jpg'
import AVT4 from '../../assets/CR4.jpg';
// import Swiper core and required modules
import { Pagination} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data=[
  {
    avatar:AVT1,
  
  },
  {
    avatar:AVT2,
  },
  {
    avatar:AVT3,

  },
  {
    avatar:AVT4,

  },
]
const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Get To Know</h5>
      <h2>Certificate</h2>

      <Swiper className="container testimonials_container" 
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
   >
        {
          data.map(({avatar},index)=>{
            return(
              <SwiperSlide key={index} className="testimonial">
              <div className="client_avatar">
                <img src={avatar}/>
              </div>
      
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  );
};

export default Testimonials;
