import React from "react";
import "./portfolio.css";
import IMG1 from '../../assets/authentication.png'
import IMG2 from '../../assets/eco.png'
// import IMG3 from '../../assets/spices-1.png'
import IMG4 from '../../assets/spices_1.png'
import IMG5 from '../../assets/ast.png'



const data=[
  {
    id:1,
    image:IMG1,
    title:"Authentication",
    github:"https://github.com/adaikkalam-1/Authentication-project",
    demo:"https://flowershopap.netlify.app"

  },
  {
    id:2,
    image:IMG2,
    title:"Ecommerce",
    github:"https://github.com/adaikkalam-1/Ecommerce",
    demo:"https://ecommerce-d2r.pages.dev/"

  },
  {
    id:3,
    image:IMG5,
    title:"Ecommerce",
    github:"https://github.com/adaikkalam-1/Asphalt_syndicate",
    demo:"https://asphaltsyndicate-clone-apj.pages.dev/"

  },
  {
    id:4,
    image:IMG4,
    title:"Ecommerce",
    github:"https://github.com/adaikkalam-1/spices_ecommerce.git",
    demo:"https://adaikkalamspices.netlify.app/"

  },
  // {
  //   id:5,
  //   image:IMG4,
  //   title:"",
  //   github:"https://github.com/adaikkalam-1/Authentication-project",
  //   demo:"https://github.com"

  // },
  // {
  //   id:6,
  //   image:IMG1,
  //   title:"Authentication",
  //   github:"https://github.com/adaikkalam-1/Authentication-project",
  //   demo:"https://github.com"

  // }
]
const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work </h5>
      <h2>My Projects</h2>
      <div className="container portfolio_container">
        {
          
          data.map(({id,image,title,github,demo}) => {
            return(
            <article key={id} className="portfolio_item">
          <div className="portfolio_item-image">
          <img src={image} alt={title} />
          </div>
          <h3>{title} </h3>
          <div className="portfolio_item-cta">
            <a href={github} className="btn" target="_blank">
              Github
            </a>
            <a href={demo} className="btn btn-primary" target="_blank">
              Live Demo
            </a>
          </div>
        </article>
          )
          })
        }
        
      </div>
    </section>
  );
};

export default Portfolio;

